<template>
    <div class="p-fluid mb-3">       
        <div class="grid">
            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                <h5>Panduan</h5>
                <ol>
                    <li>
                        <p class="line-height-3 m-0">Pastikan file upload berupa format gambar</p>
                    </li>
                    <!-- <li>
                        <p class="line-height-3 m-0">Pastikan ukuran file tidak lebih dari {asdasdasd}</p>
                    </li> -->
                </ol>
                <div class="line-height-3 m-0" style="color:red;">Note: harap ikuti petunjuk langkah demi langkah dengan cermat untuk memastikan data diunggah dengan sukses.</div>
            </div>
            
            <div class="lg:col-1">
                <Divider layout="vertical">
                    <!-- <b>OR</b> -->
                </Divider>
            </div>

            <div class="sm:col-12 md:col-12 lg:col-6">
                <BlockUI :blocked="blockPanel">
                    <form>
                        <div class="p-fluid">
                            <div class="formgrid grid">
                                <div class="field col-12 md:col-12 lg:col-6">
                                    <!-- FORM -->
                                </div>
                            </div>
                        </div>
                        <FileUpload ref="fileUpload" name="file" accept="image/*" chooseLabel="Pilih File" uploadLabel="Unggah" cancelLabel="Batal" :customUpload="true" @uploader="onUpload" :fileLimit="1">
                        <template #empty>
                            <p>Seret dan taruh file disini untuk mengunggah.</p>
                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                        </template>
                        </FileUpload>
                        <small class="p-invalid" style="color: red" v-if="errors.gambar" >{{ errors.gambar[0] }}</small>
                    </form>
                </BlockUI>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    data() {
        return {
            // upload
            blockPanel: false,
            file: null,
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    computed: {
        ...mapGetters(['errors']),
    },
    methods: {
        //UPLOAD
        onUpload(event) {

            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('gambar', this.file);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/banner/create',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;

                this.clearForms();

                this.$emit('submit');
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
            });
        },
        clearForms() {
            this.file = null;
        },
    }
}
</script>