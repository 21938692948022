<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <Create @submit="getDataTable()" ref="create"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <!--  -->
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="url_filename" header="Gambar">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.url_filename" class="text-center">
                                <Image :src="slotProps.data.url_filename" :alt="slotProps.data.url_filename" width="300" preview />
                                <br>
                                <div>{{ slotProps.data.filename }}</div>
                            </div>
                            <div v-else>
                                <Image src="no_image.jpg" alt="No Image" width="300" preview />
                            </div>
                        </template>
                    </Column>
                    <Column field="is_active" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.is_active == 'y'"/>
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteItem(slotProps.data)" v-if="permission.includes('delete')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit"/>

                <Delete @submit="getDataTable()" :item="item" ref="delete"/>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Create from '../../components/master/banner/Create.vue';
import Edit from '../../components/master/banner/Edit.vue';
import Delete from '../../components/master/banner/Delete.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Create': Create,
		'Edit': Edit,
		'Delete': Delete,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,

            // edit,delete
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DELETE
		deleteItem(item) {
			this.item = item;
            
            this.$refs.delete.deleteItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/banner',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
}
</script>